
import { defineComponent } from "vue-demi";
import ModalImage from './Image.vue';

export default defineComponent({
  name: 'ModalHeader',
  props: {
    data: {
      type: Object,
      required: true,
    }
  },
  components: {
    ModalImage
  }
})
