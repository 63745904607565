import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12b6914a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-between items-center" }
const _hoisted_2 = { class: "title leksa_title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_image = _resolveComponent("modal-image")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.data.content.title), 1),
    (_ctx.data.content.fileRequest && _ctx.data.content.action !== 'delete')
      ? (_openBlock(), _createBlock(_component_modal_image, {
          key: 0,
          data: _ctx.data.inputs.data,
          id: 'image'
        }, null, 8, ["data"]))
      : _createCommentVNode("", true)
  ]))
}