
import { defineComponent } from "vue-demi";
import { CancelIcon } from "@/plugins/icons";
import { checkFileSize, checkImageFiles } from "@/utils/files";

export default defineComponent({
  emits: ['uploadFile'],
  name: "ModalImage",
  props: {
    id: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    previewFileSrc(file: File) {
      return URL.createObjectURL(file);
    },
    uploadImage() {
      const ref = this.$refs[this.id] as HTMLInputElement;
      if (!ref.files) return;
      if (!checkFileSize(ref.files)) return;
      if (!checkImageFiles(ref.files)) return;

      this.data.file = ref.files[0];
      this.data.image = ref.files[0];
      this.$emit('uploadFile', { id: this.id, ref: this.$refs[this.id] });
    },
    deleteImage() {
      this.data.image = null;
    },
  },
  components: {
    CancelIcon,
  },
});
